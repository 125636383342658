import { graphql, useStaticQuery } from 'gatsby';

export default function useCommonTitles() {
  return useStaticQuery(graphql`
    {
      commonTitlesYaml {
        getStarted
        learnMore
        getFreeAccount
        noCreditCardRequired
      }
    }
  `).commonTitlesYaml;
}
