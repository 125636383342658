import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { buttonStyles, ButtonColor } from '../shared-client/buttonStyles';

export { ButtonColor };

const InternalLink = styled(Link)`
  ${buttonStyles}
`;

const ExternalLink = styled.a`
  ${buttonStyles}
`;

const RegularButton = styled.button`
  ${buttonStyles}
`;

export default function ButtonLink({
  to,
  href,
  onClick,
  color = ButtonColor.Primary,
  ...props
}) {
  if (to) {
    return <InternalLink to={to} $color={color} {...props} />;
  } else if (href) {
    return <ExternalLink href={href} $color={color} {...props} />;
  } else {
    return <RegularButton onClick={onClick} $color={color} {...props} />;
  }
}
