import React from 'react';

import styled from 'styled-components';
import { device } from '../utils/breakpoints';
import ButtonLink, { ButtonColor } from '../components/ButtonLink';
import RocketShip from '../images/RocketShip.svg';
import Security from '../images/Security.svg';
import MoneyBills from '../images/MoneyBills.svg';
import WorldWide from '../images/Worldwide.svg';
import BlueBackground from '../components/backgrounds/BlueBackground';
import MarginContainer from '../components/MarginContainer';
import { graphql, useStaticQuery } from 'gatsby';
import useCommonTitles from '../components/useCommonTitles';

import HomePage from './home'

const perksMobileBreakpoint = device.laptop;

const StyledH1 = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 42px;

  text-align: center;

  color: ${(props) => props.theme.white};
`;

const MainContent = styled(BlueBackground)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  padding-bottom: 200px;
  background-color: ${({ theme }) => theme.primary};

  @media ${perksMobileBreakpoint} {
    display: block;
    padding-bottom: 130px;

    & > div {
      max-width: 100%;
    }
  }
`;

const SecondaryContent = styled.div`
  background-color: #fcfdff;
  padding-top: 220px;
  padding-bottom: 200px;
  position: relative;

  @media ${perksMobileBreakpoint} {
    padding: 70px 0;
    padding-top: 700px;
  }
`;

const SecondaryContentWrapper = styled.div`
  margin: auto;
  display: flex;
  position: relative;
  justify-content: space-between;
`;

const PricingContent = styled.div`
  text-align: center;
  width: 100%;
`;

const SecondaryContentParagraph = styled.p`
  font-family: 'Open Sans', serif;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 50px;
`;

const PricingH2 = styled.h2`
  font-family: Poppins, serif;
  font-size: 42px;
  color: ${(props) => props.theme.primary};
`;

const StyledParagraphInverted = styled.p`
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  margin: 0;
  padding: 0 16px;
  color: ${(props) => props.theme.white};
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
  align-items: flex-start;

  & a:not(:last-child) {
    margin-right: 16px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    width: 100%;

    & a:not(:last-child) {
      margin-right: 0;
    }
  }
`;

// noinspection CssInvalidPropertyValue
const PerksPositionContainer = styled.div`
  left: 0;
  top: -140px;
  position: absolute;
  width: 100%;

  @media ${perksMobileBreakpoint} {
    top: -120px;
  }
`;

const PerksContainer = styled(MarginContainer)`
  display: flex;
  width: 100%;
  justify-content: center;

  @media ${perksMobileBreakpoint} {
    flex-direction: column;
    align-items: center;
  }
`;

const Perk = styled.div`
  flex: 1;
  margin-right: 10px;
  margin-left: 10px;
  width: 350px;
  padding: 12px;
  background: ${(props) => props.theme.white};
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 358px;
  box-shadow: 0px -0.05534512922167778px 1.4666459560394287px 0px rgba(20, 48, 189);

  & > svg {
    margin-bottom: 8px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    @media ${perksMobileBreakpoint} {
      width: 100%;
    }
  }

  & > div > strong {
    text-align: center;
    color: ${(props) => props.theme.primary};
    font-size: 22px;
    font-weight: 600;
  }
  & > div > span {
    margin-top: 9px;
    max-width: 250px;
    text-align: center;
    font-size: 16px;

    @media ${perksMobileBreakpoint} {
      max-width: unset;
    }
  }

  @media ${perksMobileBreakpoint} {
    margin-top: 10px;
    width: 100%;
    max-width: unset;
    flex-direction: row;
    min-height: 186px;
  }
`;

const ButtonWithTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    color: ${(props) => props.theme.white};
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`;

const PricingButton = styled(ButtonLink)`
  @media ${device.tablet} {
    margin-top: 14px;
    width: 100%;
  }
`;

const MobileLineBreak = styled.span`
  display: inline;
  @media ${device.tablet} {
    display: block;
  }
`;

const DesktopLineBreak = styled.span`
  display: block;
  @media ${device.tablet} {
    display: inline;
  }
`;

function IndexPage() {
  const { getFreeAccount, noCreditCardRequired } = useCommonTitles();
  const {
    heroSectionTitle,
    heroSectionSubtitle,
    title,
    subtitle,
    perks,
  } = useStaticQuery(graphql`
    {
      homePageYaml {
        heroSectionTitle
        heroSectionSubtitle
        title
        subtitle
        perks {
          title
          description
        }
      }
    }
  `).homePageYaml;

  const perkImages = [
    <RocketShip />,
    <Security />,
    <MoneyBills />,
    <WorldWide />,
  ];

  return (
    // <>
    //   <Seo title="Home" />
    //   <MainContent>
    //     <MarginContainer>
    //       <StyledH1>
    //         <MultilineText text={heroSectionTitle} />
    //       </StyledH1>
    //       {heroSectionSubtitle.split('\n').map((line) => (
    //         <StyledParagraphInverted key={line}>{line}</StyledParagraphInverted>
    //       ))}
    //       <ButtonsContainer>
    //         <ButtonWithTextWrapper>
    //           <ButtonLink
    //             color={ButtonColor.SecondaryWithBorder}
    //             href="/console/signup"
    //           >
    //             {getFreeAccount}
    //           </ButtonLink>
    //           <span>{noCreditCardRequired}</span>
    //         </ButtonWithTextWrapper>
    //         <PricingButton color={ButtonColor.PrimaryWithBorder} to="/pricing">
    //           Pricing
    //         </PricingButton>
    //       </ButtonsContainer>
    //     </MarginContainer>
    //   </MainContent>
    //   <SecondaryContent>
    //     <PerksPositionContainer>
    //       <PerksContainer>
    //         {perks.map((p, index) => (
    //           <Perk key={p.title}>
    //             {perkImages[index]}
    //             <div>
    //               <strong>{p.title}</strong>
    //               <span>{p.description}</span>
    //             </div>
    //           </Perk>
    //         ))}
    //       </PerksContainer>
    //     </PerksPositionContainer>
    //     <SecondaryContentWrapper>
    //       <PricingContent>
    //         <PricingH2>{title}</PricingH2>
    //         <SecondaryContentParagraph>{subtitle}</SecondaryContentParagraph>
    //         <PricingPlans />
    //       </PricingContent>
    //     </SecondaryContentWrapper>
    //   </SecondaryContent>
    //   <JoinTheCloudSection />
    // </>
    <>
      <HomePage/>
    </>
  );
}

export default IndexPage;
